<template>
  <a-page
    :image_source="image_source"
    :image_height="image_height"
    :title="$t('welcome') + ', ' + current_user.name"
  >
    <template v-slot:header_options>
      <a-btn-pdf
        v-if="has_permission('export_pdf')"
        :config="pdf_config"
        class="mr-4"
      ></a-btn-pdf>
      <div v-if="show_production_mode" style="max-width: 200px;">
        <v-select
          v-model="commission"
          :items="commission_types"
          item-text="name"
          item-value="id"
          :label="$t('billing_type')"
          :rules="[v => !!v || $t('required')]"
          class="mr-4"
          color="#fff"
          rounded
          outlined
          dense
          hide-details
        >
        </v-select>
      </div>
      <a-rms-import-dates ref="rms_import_dates"></a-rms-import-dates>
    </template>

    <dashboard-rms-group-app
      v-if="current_hotel && isGroup"
      ref="dashboard_rms_hotelsdot_group"
    ></dashboard-rms-group-app>
    <dashboard-rms-app
      v-if="current_hotel && !isGroup"
      ref="dashboard_rms_app"
    ></dashboard-rms-app>
  </a-page>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import aPage from "@/components/aPage";
import dashboardRmsApp from "@/components/app/dashboardRmsApp";
import dashboardRmsGroupApp from "@/components/app/dashboardRmsGroupApp";
import aRmsImportDates from "@/components/rms/aRmsImportDates.vue";
import aBtnPdf from "@/components/aBtnPdf";

export default {
  components: {
    aPage,
    dashboardRmsApp,
    dashboardRmsGroupApp,
    aRmsImportDates,
    aBtnPdf
  },
  data: () => ({
    image_source: "/img/header.jpg" //Mandatory path
  }),
  computed: {
    image_height() {
      return this.isGroup ? 130 : 505;
    },
    isGroup() {
      return this.current_hotel && this.current_hotel.children_number > 0;
    },
    show_production_mode() {
      return this.current_user && this.current_hotel
        ? this.current_user.permissions.rms_production_mode &&
            this.current_hotel.permissions.rms_production_mode
        : false;
    },
    pdf_config() {
      return {
        orientation: "portrait",
        filename: this.isGroup
          ? this.$t("dashboard_group")
          : this.$t("dashboard"),
        elements: [
          {
            id: this.isGroup ? "dashboard-rms-group-app" : "dashboard_rms_app",
            is_table: false
          }
        ],
        hotel_name: this.current_hotel.name,
        section_name: this.isGroup
          ? this.$t("dashboard_group")
          : this.$t("dashboard")
      };
    },
    commission: {
      get() {
        return this.$store.state.rms.year_sales_commission;
      },
      set(value) {
        this.$store.dispatch("setRmsYearSalesCommission", value);
      }
    },
    ...mapState({
      available_permissions: state => state.user.available_permissions
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      current_user: "current_user",
      has_permission: "has_permission",
      commission_types: "commission_types"
    })
  },
  created() {
    if (this.show_production_mode && this.commission === "") {
      this.commission = this.commission_types[0].id;
    }
  }
};
</script>
