<template>
  <v-hover v-slot="{ hover }">
    <a-card
      class="hotel-list-item"
      :title="hotel.name"
      :style="'height:' + height + 'px;'"
      header_color="grey lighten-4"
      :class="hover ? 'elevation-4' : 'elevation-1'"
    >
      <template v-slot:pretitle>
        <v-avatar :color="hotel.settings.color" class="mr-1" size="16">
          <span class="white--text font-weight-bold" style="font-size:10px;">
            {{ hotel.settings.stars }}
          </span>
        </v-avatar>
      </template>
      <template v-slot:subtitle v-if="currency_converted">
        <a-currency-conversion :hotel="hotel"></a-currency-conversion>
      </template>
      <template v-slot:options>
        <v-row v-if="hotel_data" style="max-width:850px;">
          <v-col cols="3" class="">
            <div class="d-flex justify-center align-end">
              <a-period period="ytd" dense class="mr-2"></a-period>
              <a-kpi
                kpi="room_revenue"
                :value="hotel_data.ytd.value"
                :vs_values="[hotel_data.ytd.vs_value]"
                :vs_periods="[dashboard_group_vs_data]"
                x_small
              ></a-kpi>
            </div>
            <v-progress-linear
              :value="getPercent(hotel_data.ytd.value, hotel_data.ytd.vs_value)"
              rounded
              height="4"
              width="100"
              class="mt-3 px-6"
              background-color="grey lighten-3"
              :color="
                hotel_data.ytd.value > hotel_data.ytd.vs_value
                  ? 'success'
                  : 'blue'
              "
            ></v-progress-linear>
          </v-col>
          <v-col cols="3">
            <div class="d-flex justify-center align-end">
              <a-period period="full_year" dense class="mr-2"></a-period>
              <a-kpi
                kpi="room_revenue"
                :value="hotel_data.full_year.value"
                :vs_values="[hotel_data.full_year.vs_value]"
                :vs_periods="[dashboard_group_vs_data]"
                background-color="grey lighten-3"
                x_small
              ></a-kpi>
            </div>
            <v-progress-linear
              :value="
                getPercent(
                  hotel_data.full_year.value,
                  hotel_data.full_year.vs_value
                )
              "
              rounded
              height="4"
              width="100"
              class="mt-3 px-6"
              background-color="grey lighten-3"
              :color="
                hotel_data.full_year.value > hotel_data.full_year.vs_value
                  ? 'success'
                  : 'blue'
              "
            ></v-progress-linear>
          </v-col>
          <v-col cols="3">
            <div class="d-flex justify-center align-end">
              <a-period period="mtd" dense class="mr-2"></a-period>
              <a-kpi
                kpi="room_revenue"
                :value="hotel_data.mtd.value"
                :vs_values="[hotel_data.mtd.vs_value]"
                :vs_periods="[dashboard_group_vs_data]"
                x_small
              ></a-kpi>
            </div>
            <v-progress-linear
              :value="getPercent(hotel_data.mtd.value, hotel_data.mtd.vs_value)"
              rounded
              height="4"
              width="100"
              class="mt-3 px-6"
              background-color="grey lighten-3"
              :color="
                hotel_data.mtd.value > hotel_data.mtd.vs_value
                  ? 'success'
                  : 'blue'
              "
            ></v-progress-linear>
          </v-col>
          <v-col cols="3">
            <div class="d-flex justify-center align-end">
              <a-period period="full_month" dense class="mr-2"></a-period>
              <a-kpi
                kpi="room_revenue"
                :value="hotel_data.full_month.value"
                :vs_values="[hotel_data.full_month.vs_value]"
                :vs_periods="[dashboard_group_vs_data]"
                x_small
              ></a-kpi>
            </div>
            <v-progress-linear
              :value="
                getPercent(
                  hotel_data.full_month.value,
                  hotel_data.full_month.vs_value
                )
              "
              rounded
              height="4"
              width="100"
              class="mt-3 px-6"
              background-color="grey lighten-3"
              :color="
                hotel_data.full_month.value > hotel_data.full_month.vs_value
                  ? 'success'
                  : 'blue'
              "
            ></v-progress-linear>
          </v-col>
        </v-row>
      </template>
      <v-window v-model="hotel_list_items_slide" v-if="hotel_data">
        <v-window-item :value="0">
          <v-row v-if="hotel_data" no-gutters class="pt-2">
            <v-col cols="8">
              <v-row class="text-center columns-auto" no-gutters>
                <v-col cols="1" class="d-flex align-center justify-center">
                  <a-period-compare
                    :periods="['ytd', dashboard_group_vs_data]"
                    vertical
                  ></a-period-compare>
                </v-col>
                <v-col cols="2">
                  <a-label class="mb-2">
                    {{ $t("occupancy") }}
                  </a-label>
                  <a-kpi
                    kpi="occupancy"
                    :value="hotel_data.year.occupancy[0].otb"
                    :vs_values="[
                      hotel_data.year.occupancy[0][dashboard_group_vs_data]
                    ]"
                    :vs_periods="[dashboard_group_vs_data]"
                    small
                  ></a-kpi>
                </v-col>
                <v-col cols="2">
                  <a-label class="mb-2">
                    {{ $t("adr") }}
                  </a-label>
                  <a-kpi
                    kpi="adr"
                    :value="hotel_data.year.adr[0].otb"
                    :vs_values="[
                      hotel_data.year.adr[0][dashboard_group_vs_data]
                    ]"
                    :vs_periods="[dashboard_group_vs_data]"
                    small
                  ></a-kpi>
                </v-col>
                <v-col cols="2">
                  <a-label class="mb-2">
                    {{ $t("revpar") }}
                  </a-label>

                  <a-kpi
                    kpi="revpar"
                    :value="hotel_data.year.revpar[0].otb"
                    :vs_values="[
                      hotel_data.year.revpar[0][dashboard_group_vs_data]
                    ]"
                    :vs_periods="[dashboard_group_vs_data]"
                    small
                  ></a-kpi>
                </v-col>
                <v-col cols="2">
                  <a-label class="mb-2">
                    {{ $t("room_revenue") }}
                  </a-label>
                  <a-kpi
                    kpi="room_revenue"
                    :value="hotel_data.year.room_revenue[0].otb"
                    :vs_values="[
                      hotel_data.year.room_revenue[0][dashboard_group_vs_data]
                    ]"
                    :vs_periods="[dashboard_group_vs_data]"
                    small
                  ></a-kpi>
                </v-col>
                <v-col cols="2">
                  <a-label class="mb-2">
                    {{ $t("intermediation") }}
                  </a-label>
                  <a-kpi
                    kpi="intermediation"
                    :value="hotel_data.year.intermediation[0].otb"
                    :vs_values="[
                      hotel_data.year.intermediation[0][
                        'dashboard_group_vs_data'
                      ]
                    ]"
                    :vs_periods="[dashboard_group_vs_data]"
                    small
                  ></a-kpi>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="3">
              <a-dashboard-year-sales-roomrevenue-monthly-chart
                class="mb-n2 "
                :series="hotel_data.year_sales_monthly_graph.kpis.room_revenue"
                :labels="hotel_data.year_sales_monthly_graph.labels"
                height="70"
                :id="
                  'a-dashboard-year-sales-roomrevenue-monthly-chart-' + hotel.id
                "
              ></a-dashboard-year-sales-roomrevenue-monthly-chart>
            </v-col>
            <v-col cols="1" class="d-flex justify-end">
              <div style="position:relative">
                <v-btn
                  style="position: absolute; top: 50%; transform: translateY(-50%); right:0 "
                  fab
                  color="secondary"
                  @click.prevent="setCurrentHotel"
                  outlined
                  ><v-icon>mdi-chevron-right</v-icon></v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-window-item>
        <v-window-item :value="1">
          <v-row v-if="hotel_data" no-gutters class="pt-2">
            <v-col cols="8">
              <v-row class="text-center columns-auto" no-gutters>
                <v-col cols="1">
                  <a-period-compare
                    :periods="['mtd', dashboard_group_vs_data]"
                    vertical
                  ></a-period-compare>
                </v-col>
                <v-col cols="2">
                  <a-label class="mb-2">
                    {{ $t("occupancy") }}
                  </a-label>
                  <a-kpi
                    kpi="occupancy"
                    :value="hotel_data.month.occupancy[0].otb"
                    :vs_values="[
                      hotel_data.month.occupancy[0][dashboard_group_vs_data]
                    ]"
                    :vs_periods="[dashboard_group_vs_data]"
                    small
                  ></a-kpi>
                </v-col>
                <v-col cols="2">
                  <a-label class="mb-2">
                    {{ $t("adr") }}
                  </a-label>
                  <a-kpi
                    kpi="adr"
                    :value="hotel_data.month.adr[0].otb"
                    :vs_values="[
                      hotel_data.month.adr[0][dashboard_group_vs_data]
                    ]"
                    :vs_periods="[dashboard_group_vs_data]"
                    small
                  ></a-kpi>
                </v-col>
                <v-col cols="2">
                  <a-label class="mb-2">
                    {{ $t("revpar") }}
                  </a-label>
                  <a-kpi
                    kpi="revpar"
                    :value="hotel_data.month.revpar[0].otb"
                    :vs_values="[
                      hotel_data.month.revpar[0][dashboard_group_vs_data]
                    ]"
                    :vs_periods="[dashboard_group_vs_data]"
                    small
                  ></a-kpi>
                </v-col>
                <v-col cols="2">
                  <a-label class="mb-2">
                    {{ $t("room_revenue") }}
                  </a-label>
                  <a-kpi
                    kpi="room_revenue"
                    :value="hotel_data.month.room_revenue[0].otb"
                    :vs_values="[
                      hotel_data.month.room_revenue[0][
                        'dashboard_group_vs_data'
                      ]
                    ]"
                    :vs_periods="[dashboard_group_vs_data]"
                    small
                  ></a-kpi>
                </v-col>
                <v-col cols="2">
                  <a-label class="mb-2">
                    {{ $t("intermediation") }}
                  </a-label>
                  <a-kpi
                    kpi="intermediation"
                    :value="hotel_data.month.intermediation[0].otb"
                    :vs_values="[
                      hotel_data.month.intermediation[0][
                        'dashboard_group_vs_data'
                      ]
                    ]"
                    :vs_periods="[dashboard_group_vs_data]"
                    small
                  ></a-kpi>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4">
              <a-pickup-roomrevenue-revpar-chart
                height="70"
                class="mb-n2"
                :id="
                  'a-pickup-roomrevenue-revpar-chart-current-month-' + hotel.id
                "
                :series="hotel_data.kpis_pickup_month_graphs.kpis"
                :labels="hotel_data.kpis_pickup_month_graphs.labels"
                hide_x
              ></a-pickup-roomrevenue-revpar-chart>
            </v-col>
          </v-row>
        </v-window-item>
        <v-window-item :value="2">
          <v-row v-if="hotel_data" no-gutters class="pt-2">
            <v-col cols="8">
              <v-row class="text-center columns-auto" no-gutters>
                <v-col cols="1">
                  <div class="mb-3">
                    <a-period-compare
                      :periods="['mtd', dashboard_group_vs_data]"
                      vertical
                    ></a-period-compare>
                  </div>
                </v-col>
                <v-col cols="2">
                  <a-label class="mb-2">
                    {{ $t("occupancy") }}
                  </a-label>
                  <a-kpi
                    kpi="occupancy"
                    :value="hotel_data.next_month.occupancy[0].otb"
                    :vs_values="[
                      hotel_data.next_month.occupancy[0][
                        'dashboard_group_vs_data'
                      ]
                    ]"
                    :vs_periods="[dashboard_group_vs_data]"
                    small
                  ></a-kpi>
                </v-col>
                <v-col cols="2">
                  <a-label class="mb-2">
                    {{ $t("adr") }}
                  </a-label>
                  <a-kpi
                    kpi="adr"
                    :value="hotel_data.next_month.adr[0].otb"
                    :vs_values="[
                      hotel_data.next_month.adr[0][dashboard_group_vs_data]
                    ]"
                    :vs_periods="[dashboard_group_vs_data]"
                    small
                  ></a-kpi>
                </v-col>
                <v-col cols="2">
                  <a-label class="mb-2">
                    {{ $t("revpar") }}
                  </a-label>
                  <a-kpi
                    kpi="revpar"
                    :value="hotel_data.next_month.revpar[0].otb"
                    :vs_values="[
                      hotel_data.next_month.revpar[0]['dashboard_group_vs_data']
                    ]"
                    :vs_periods="[dashboard_group_vs_data]"
                    small
                  ></a-kpi>
                </v-col>
                <v-col cols="2">
                  <a-label class="mb-2">
                    {{ $t("room_revenue") }}
                  </a-label>
                  <a-kpi
                    kpi="room_revenue"
                    :value="hotel_data.next_month.room_revenue[0].otb"
                    :vs_values="[
                      hotel_data.next_month.room_revenue[0][
                        'dashboard_group_vs_data'
                      ]
                    ]"
                    :vs_periods="[dashboard_group_vs_data]"
                    small
                  ></a-kpi>
                </v-col>
                <v-col cols="2">
                  <a-label class="mb-2">
                    {{ $t("intermediation") }}
                  </a-label>
                  <a-kpi
                    kpi="intermediation"
                    :value="hotel_data.next_month.intermediation[0].otb"
                    :vs_values="[
                      hotel_data.next_month.intermediation[0][
                        'dashboard_group_vs_data'
                      ]
                    ]"
                    :vs_periods="[dashboard_group_vs_data]"
                    small
                  ></a-kpi>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4">
              <a-pickup-roomrevenue-revpar-chart
                height="70px"
                class="mb-n2"
                :id="'a-pickup-roomrevenue-revpar-chart-next-month-' + hotel.id"
                :series="hotel_data.kpis_pickup_next_month_graphs.kpis"
                :labels="hotel_data.kpis_pickup_next_month_graphs.labels"
                hide_x
              ></a-pickup-roomrevenue-revpar-chart>
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>
      <v-window v-else>
        <v-window-item>
          <v-row>
            <v-col cols="2" class="pb-0" v-for="index in 6" :key="index">
              <v-skeleton-loader type="chip"></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="pb-0" v-for="index in 6" :key="index">
              <v-skeleton-loader type="text"></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>
    </a-card>
  </v-hover>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import aCard from "@/components/aCard.vue";
import aKpi from "@/components/rms_ui/aKpi.vue";
import aPeriod from "@/components/rms_ui/aPeriod.vue";
import aPeriodCompare from "@/components/rms_ui/aPeriodCompare.vue";
import aLabel from "@/components/rms_ui/aLabel.vue";
import aDashboardYearSalesRoomrevenueMonthlyChart from "@/components/chart/aDashboardYearSalesRoomrevenueMonthlyChart.vue";
import aPickupRoomrevenueRevparChart from "@/components/chart/aPickupRoomrevenueRevparChart.vue";
import aCurrencyConversion from "@/components/aCurrencyConversion.vue";

export default {
  name: "a-dashboard-hotel-list-item",
  components: {
    aCurrencyConversion,
    aCard,
    aKpi,
    aPeriod,
    aPeriodCompare,
    aLabel,
    aDashboardYearSalesRoomrevenueMonthlyChart,
    aPickupRoomrevenueRevparChart
  },
  watch: {
    visible(v) {
      if (v && !this.loaded) {
        this.$store.dispatch("getHotelKpisPeriod", this.hotel).then(() => {
          this.loaded = true;
        });
      }
    },
    dashboard_group_vs_data(v) {
      this.loaded = false;
      this.$store.dispatch("getHotelKpisPeriod", this.hotel).then(() => {
        this.loaded = true;
      });
    },
    commission(v) {
      this.loaded = false;
      this.$store.dispatch("getHotelKpisPeriod", this.hotel).then(() => {
        this.loaded = true;
      });
    }
  },
  props: {
    hotel: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    visible: false,
    loaded: false,
    height: "auto"
  }),
  methods: {
    setCurrentHotel() {
      this.$store.dispatch("setCurrentHotel", this.hotel);
    },
    getPercent(v1, v2) {
      return (v1 / v2) * 100;
    }
  },
  computed: {
    hotel_list_items_slide: {
      get() {
        return this.$store.state.rms.hotel_list_items_slide;
      },
      set(value) {
        this.$store.dispatch("setHotelListItemSlide", value);
      }
    },
    dashboard_group_vs_data: {
      get() {
        return this.$store.state.rms.dashboard_group_vs_data;
      },
      set(value) {
        this.$store.dispatch("setDashboardGroupVsData", value);
      }
    },
    commission: {
      get() {
        return this.$store.state.rms.year_sales_commission;
      },
      set(value) {
        this.$store.dispatch("setRmsYearSalesCommission", value);
      }
    },
    hotel_data() {
      return this.group_hotels[this.hotel.id];
    },
    currency_converted() {
      return (
        this.hotel.currency_default &&
        this.current_hotel.currency_default &&
        this.hotel.currency_default !== this.current_hotel.currency_default
      );
    },
    locale_hotel() {
      return this.get_language_by_locale(
        this.hotel.settings.locale,
        this.hotel.settings.locale_decimals
      );
    },
    ...mapState({
      group_hotels: state => state.rms.group_hotels,
      group_hotels_status: state => state.rms.group_hotels_status
    }),
    ...mapGetters({
      current_hotel: "current_hotel",
      get_language_by_locale: "get_language_by_locale"
    })
  },
  mounted() {
    this.visible = true;
  }
};
</script>
<style scoped lang="scss">
.hotel-list-item {
  transition: 0.4s all;
  .columns-auto {
    .col-2 {
      max-width: none;
    }
  }
}
</style>
