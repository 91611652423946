<template>
  <v-hover v-slot="{ hover }">
    <a-card
      class="hotel-list-item"
      :title="hotel.name"
      :style="'height:' + height + 'px;'"
      header_color="hotel-group-header"
      :class="hover ? 'elevation-4' : 'elevation-1'"
    >
      <template v-slot:options>
        <v-row v-if="hotel_data" style="max-width:850px;">
          <v-col cols="3" class="">
            <div class="d-flex justify-center align-end">
              <a-period period="ytd" dense class="mr-2"></a-period>
              <a-kpi
                kpi="room_revenue"
                :value="hotel_data.ytd.value"
                :vs_values="[hotel_data.ytd.vs_value]"
                :vs_periods="[vs_spot]"
                x_small
              ></a-kpi>
            </div>
            <v-progress-linear
              :value="getPercent(hotel_data.ytd.value, hotel_data.ytd.vs_value)"
              rounded
              height="4"
              width="100"
              class="mt-3 px-6"
              background-color="grey lighten-3"
              :color="
                hotel_data.ytd.value > hotel_data.ytd.vs_value
                  ? 'success'
                  : 'blue'
              "
            ></v-progress-linear>
          </v-col>
          <v-col cols="3">
            <div class="d-flex justify-center align-end">
              <a-period period="full_year" dense class="mr-2"></a-period>
              <a-kpi
                kpi="room_revenue"
                :value="hotel_data.full_year.value"
                :vs_values="[hotel_data.full_year.vs_value]"
                :vs_periods="[vs_spot]"
                background-color="grey lighten-3"
                x_small
              ></a-kpi>
            </div>
            <v-progress-linear
              :value="
                getPercent(
                  hotel_data.full_year.value,
                  hotel_data.full_year.vs_value
                )
              "
              rounded
              height="4"
              width="100"
              class="mt-3 px-6"
              background-color="grey lighten-3"
              :color="
                hotel_data.full_year.value > hotel_data.full_year.vs_value
                  ? 'success'
                  : 'blue'
              "
            ></v-progress-linear>
          </v-col>
          <v-col cols="3">
            <div class="d-flex justify-center align-end">
              <a-period period="mtd" dense class="mr-2"></a-period>
              <a-kpi
                kpi="room_revenue"
                :value="hotel_data.mtd.value"
                :vs_values="[hotel_data.mtd.vs_value]"
                :vs_periods="[vs_spot]"
                x_small
              ></a-kpi>
            </div>
            <v-progress-linear
              :value="getPercent(hotel_data.mtd.value, hotel_data.mtd.vs_value)"
              rounded
              height="4"
              width="100"
              class="mt-3 px-6"
              background-color="grey lighten-3"
              :color="
                hotel_data.mtd.value > hotel_data.mtd.vs_value
                  ? 'success'
                  : 'blue'
              "
            ></v-progress-linear>
          </v-col>
          <v-col cols="3">
            <div class="d-flex justify-center align-end">
              <a-period period="full_month" dense class="mr-2"></a-period>
              <a-kpi
                kpi="room_revenue"
                :value="hotel_data.full_month.value"
                :vs_values="[hotel_data.full_month.vs_value]"
                :vs_periods="[vs_spot]"
                x_small
              ></a-kpi>
            </div>
            <v-progress-linear
              :value="
                getPercent(
                  hotel_data.full_month.value,
                  hotel_data.full_month.vs_value
                )
              "
              rounded
              height="4"
              width="100"
              class="mt-3 px-6"
              background-color="grey lighten-3"
              :color="
                hotel_data.full_month.value > hotel_data.full_month.vs_value
                  ? 'success'
                  : 'blue'
              "
            ></v-progress-linear>
          </v-col>
        </v-row>
      </template>
      <v-window v-model="hotel_list_items_slide" v-if="hotel_data">
        <v-window-item :value="0">
          <v-row v-if="hotel_data" no-gutters class="pt-2">
            <v-col cols="8">
              <v-row class="text-center" no-gutters>
                <v-col cols="1" class="d-flex align-center justify-center">
                  <div class="mb-3">
                    <a-period-compare
                      :periods="['ytd', vs_spot]"
                      vertical
                    ></a-period-compare>
                  </div>
                </v-col>
                <v-col cols="2">
                  <a-label class="mb-2">
                    {{ $t("occupancy") }}
                  </a-label>
                  <a-kpi
                    kpi="occupancy"
                    :value="hotel_data.year.occupancy[0].otb"
                    :vs_values="[hotel_data.year.occupancy[0][vs_spot]]"
                    :vs_periods="[vs_spot]"
                    small
                  ></a-kpi>
                </v-col>
                <v-col cols="2">
                  <a-label class="mb-2">
                    {{ $t("adr") }}
                  </a-label>
                  <a-kpi
                    kpi="adr"
                    :value="hotel_data.year.adr[0].otb"
                    :vs_values="[hotel_data.year.adr[0][vs_spot]]"
                    :vs_periods="[vs_spot]"
                    small
                  ></a-kpi>
                </v-col>
                <v-col cols="2">
                  <a-label class="mb-2">
                    {{ $t("revpar") }}
                  </a-label>

                  <a-kpi
                    kpi="revpar"
                    :value="hotel_data.year.revpar[0].otb"
                    :vs_values="[hotel_data.year.revpar[0][vs_spot]]"
                    :vs_periods="[vs_spot]"
                    small
                  ></a-kpi>
                </v-col>
                <v-col cols="2">
                  <a-label class="mb-2">
                    {{ $t("room_revenue") }}
                  </a-label>
                  <a-kpi
                    kpi="room_revenue"
                    :value="hotel_data.year.room_revenue[0].otb"
                    :vs_values="[hotel_data.year.room_revenue[0][vs_spot]]"
                    :vs_periods="[vs_spot]"
                    small
                  ></a-kpi>
                </v-col>
                <v-col cols="2">
                  <a-label class="mb-2">
                    {{ $t("intermediation") }}
                  </a-label>
                  <a-kpi
                    kpi="intermediation"
                    :value="hotel_data.year.intermediation[0].otb"
                    :vs_values="[hotel_data.year.intermediation[0][vs_spot]]"
                    :vs_periods="[vs_spot]"
                    small
                  ></a-kpi>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="3"> </v-col>
            <v-col cols="1" class="d-flex justify-end"> </v-col>
          </v-row>
        </v-window-item>
        <v-window-item :value="1">
          <v-row v-if="hotel_data" no-gutters class="pt-2">
            <v-col cols="8">
              <v-row class="text-center" no-gutters>
                <v-col cols="1">
                  <div class="mb-3">
                    <a-period-compare
                      :periods="['mtd', vs_spot]"
                      vertical
                    ></a-period-compare>
                  </div>
                </v-col>
                <v-col cols="2">
                  <a-label class="mb-2">
                    {{ $t("occupancy") }}
                  </a-label>
                  <a-kpi
                    kpi="occupancy"
                    :value="hotel_data.month.occupancy[0].otb"
                    :vs_values="[hotel_data.month.occupancy[0][vs_spot]]"
                    :vs_periods="[vs_spot]"
                    small
                  ></a-kpi>
                </v-col>
                <v-col cols="2">
                  <a-label class="mb-2">
                    {{ $t("adr") }}
                  </a-label>
                  <a-kpi
                    kpi="adr"
                    :value="hotel_data.month.adr[0].otb"
                    :vs_values="[hotel_data.month.adr[0][vs_spot]]"
                    :vs_periods="[vs_spot]"
                    small
                  ></a-kpi>
                </v-col>
                <v-col cols="2">
                  <a-label class="mb-2">
                    {{ $t("revpar") }}
                  </a-label>
                  <a-kpi
                    kpi="revpar"
                    :value="hotel_data.month.revpar[0].otb"
                    :vs_values="[hotel_data.month.revpar[0][vs_spot]]"
                    :vs_periods="[vs_spot]"
                    small
                  ></a-kpi>
                </v-col>
                <v-col cols="2">
                  <a-label class="mb-2">
                    {{ $t("room_revenue") }}
                  </a-label>
                  <a-kpi
                    kpi="room_revenue"
                    :value="hotel_data.month.room_revenue[0].otb"
                    :vs_values="[hotel_data.month.room_revenue[0][vs_spot]]"
                    :vs_periods="[vs_spot]"
                    small
                  ></a-kpi>
                </v-col>
                <v-col cols="2">
                  <a-label class="mb-2">
                    {{ $t("intermediation") }}
                  </a-label>
                  <a-kpi
                    kpi="intermediation"
                    :value="hotel_data.month.intermediation[0].otb"
                    :vs_values="[hotel_data.month.intermediation[0][vs_spot]]"
                    :vs_periods="[vs_spot]"
                    small
                  ></a-kpi>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4"> </v-col>
          </v-row>
        </v-window-item>
        <v-window-item :value="2">
          <v-row v-if="hotel_data" no-gutters class="pt-2">
            <v-col cols="8">
              <v-row class="text-center" no-gutters>
                <v-col cols="1">
                  <div class="mb-3">
                    <a-period-compare
                      :periods="['mtd', vs_spot]"
                      vertical
                    ></a-period-compare>
                  </div>
                </v-col>
                <v-col cols="2">
                  <a-label class="mb-2">
                    {{ $t("occupancy") }}
                  </a-label>
                  <a-kpi
                    kpi="occupancy"
                    :value="hotel_data.next_month.occupancy[0].otb"
                    :vs_values="[hotel_data.next_month.occupancy[0][vs_spot]]"
                    :vs_periods="[vs_spot]"
                    small
                  ></a-kpi>
                </v-col>
                <v-col cols="2">
                  <a-label class="mb-2">
                    {{ $t("adr") }}
                  </a-label>
                  <a-kpi
                    kpi="adr"
                    :value="hotel_data.next_month.adr[0].otb"
                    :vs_values="[hotel_data.next_month.adr[0][vs_spot]]"
                    :vs_periods="[vs_spot]"
                    small
                  ></a-kpi>
                </v-col>
                <v-col cols="2">
                  <a-label class="mb-2">
                    {{ $t("revpar") }}
                  </a-label>
                  <a-kpi
                    kpi="revpar"
                    :value="hotel_data.next_month.revpar[0].otb"
                    :vs_values="[hotel_data.next_month.revpar[0][vs_spot]]"
                    :vs_periods="[vs_spot]"
                    small
                  ></a-kpi>
                </v-col>
                <v-col cols="2">
                  <a-label class="mb-2">
                    {{ $t("room_revenue") }}
                  </a-label>
                  <a-kpi
                    kpi="room_revenue"
                    :value="hotel_data.next_month.room_revenue[0].otb"
                    :vs_values="[
                      hotel_data.next_month.room_revenue[0][vs_spot]
                    ]"
                    :vs_periods="[vs_spot]"
                    small
                  ></a-kpi>
                </v-col>
                <v-col cols="2">
                  <a-label class="mb-2">
                    {{ $t("intermediation") }}
                  </a-label>
                  <a-kpi
                    kpi="intermediation"
                    :value="hotel_data.next_month.intermediation[0].otb"
                    :vs_values="[
                      hotel_data.next_month.intermediation[0][vs_spot]
                    ]"
                    :vs_periods="[vs_spot]"
                    small
                  ></a-kpi>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4"> </v-col>
          </v-row>
        </v-window-item>
      </v-window>
      <v-window v-else>
        <v-window-item>
          <v-row>
            <v-col cols="2" class="pb-0" v-for="index in 6" :key="index">
              <v-skeleton-loader type="chip"></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" class="pb-0" v-for="index in 6" :key="index">
              <v-skeleton-loader type="text"></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>
    </a-card>
  </v-hover>
</template>

<script>
import { mapState } from "vuex";

import aCard from "@/components/aCard.vue";
import aKpi from "@/components/rms_ui/aKpi.vue";
import aPeriod from "@/components/rms_ui/aPeriod.vue";
import aPeriodCompare from "@/components/rms_ui/aPeriodCompare.vue";
import aLabel from "@/components/rms_ui/aLabel.vue";

export default {
  name: "a-dashboard-hotel-group-list-item",
  components: {
    aCard,
    aKpi,
    aPeriod,
    aPeriodCompare,
    aLabel
  },
  watch: {
    visible(v) {
      if (v && !this.loaded) {
        this.$store.dispatch("getHotelKpisPeriod", this.hotel).then(() => {
          this.loaded = true;
        });
      }
    },
    dashboard_group_vs_data(v) {
      this.loaded = false;
      this.$store.dispatch("getHotelKpisPeriod", this.hotel).then(() => {
        this.loaded = true;
        this.vs_spot = this.dashboard_group_vs_data;
      });
    },
    commission(v) {
      this.loaded = false;
      this.$store.dispatch("getHotelKpisPeriod", this.hotel).then(() => {
        this.loaded = true;
      });
    }
  },
  props: {
    hotel: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    isActive: false,
    visible: false,
    loaded: false,
    height: "auto",
    vs_spot: "bud"
  }),
  methods: {
    setCurrentHotel() {
      this.$store.dispatch("setCurrentHotel", this.hotel);
    },
    getPercent(v1, v2) {
      return (v1 / v2) * 100;
    }
  },
  computed: {
    hotel_list_items_slide: {
      get() {
        return this.$store.state.rms.hotel_list_items_slide;
      },
      set(value) {
        this.$store.dispatch("setHotelListItemSlide", value);
      }
    },
    dashboard_group_vs_data: {
      get() {
        return this.$store.state.rms.dashboard_group_vs_data;
      },
      set(value) {
        this.$store.dispatch("setDashboardGroupVsData", value);
      }
    },
    commission: {
      get() {
        return this.$store.state.rms.year_sales_commission;
      },
      set(value) {
        this.$store.dispatch("setRmsYearSalesCommission", value);
      }
    },
    hotel_data() {
      return this.group_hotels[this.hotel.id];
    },
    ...mapState({
      group_hotels: state => state.rms.group_hotels,
      group_hotels_status: state => state.rms.group_hotels_status
    })
  },
  mounted() {
    this.visible = true;
  }
};
</script>
<style scoped lang="scss">
.hotel-list-item {
  transition: 0.4s all;
}
*::v-deep .hotel-group-header {
  font-size: 1em;
  background-color: #113559d9 !important;
  color: white;
}
</style>
